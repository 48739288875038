import React, { useEffect } from 'react';
// import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
// import {  } from 'react-redux';
import ReactGA from 'react-ga4';
import * as URLS from '../es5Routes';

import Navigation from './navigation/Navigation';
import AccountCreated from './customers/account/AccountCreated';
import BarnsPage from './customers/barns/BarnsPage';
import BarnFormPage from './customers/barns/BarnFormPage';
import BarnUsers from './customers/barns/BarnUsers';
import CreateAccount from './customers/account/CreateAccount';
import CalendarPage from './calendar/pages/CalendarPage';
import EventDetails from './calendar/pages/EventDetails';
import CalendarEntryStatus from './calendar/pages/CalendarEntryStatus';
import CalendarRideTimesPage from './calendar/pages/CalendarRideTimesPage';
import CommunityAddPage from './customers/communities/CommunityAddPage';
import CommunityInvitesPage from './customers/communities/CommunityInvitesPage';
import CommunityPage from './customers/communities/CommunityPage';
import Dashboard from './customers/Dashboard';
import EntryPage from './customers/EntryPage';
import EventsPage from './admin/events/EventsPage';
import EventManagement from './admin/events/EventManagement';
import EventForm from './admin/events/EventForm';
import HorsePage from './customers/horses/HorsePage';
import HorseForm from './customers/horses/HorseForm';
import HorseManagement from './customers/horses/HorseManagement';
import HorseManageFeed from './customers/horses/HorseManageFeed';
import HorseFeed from './customers/horses/HorseFeed';
import InvalidToken from './customers/account/InvalidToken';
import JumpsPage from './admin/jumps/pages/JumpsPage';
import JumpManagement from './admin/jumps/pages/JumpManagement';
import JumpAddFormPage from './admin/jumps/JumpAddFormPage';
import JumpForm from './admin/jumps/pages/JumpForm';
// import JumpSubForm from './admin/jumps/pages/JumpSubForm';
import Landing from './Landing';
import NewLogin from './customers/account/NewLogin';
import LoginInvalid from './customers/account/LoginInvalid';
import InitiateResetPass from './customers/account/InitiateResetPass';
import ResetPass from './customers/account/ResetPass';
import ResetSent from './customers/account/ResetSent';
import MediaFormPage from './customers/media/MediaFormPage';
import MediasManage from './customers/media/MediasManage';
import SalesHorseList from './sales/SalesHorseList';
import SaleHorseListingPage from './sales/SaleHorseListingPage';
import SchedulingPage from './scheduler/pages/SchedulingPage';
import SJCourseInfo from './calendar/pages/SJCourseInfo';
import TackPage from './admin/tack/TackPage';
import TackFormPage from './admin/tack/TackFormPage';
import TackSaleTable from './admin/tack/TackSaleTable';
import TackSaleListingPage from './admin/tack/TackSaleListingPage';
import TackManage from './admin/tack/TackManage';
import TrainerFormPage from './customers/trainers/TrainerFormPage';
import TrainerListingPage from './customers/trainers/TrainerListingPage';
import TrainerManage from './customers/trainers/TrainerManage';
import UserAdmin from './admin/users/UserAdmin';
import UserEditFormPage from './customers/account/UserEditFormPage';
import UserAdminEditFormPage from './admin/users/UserAdminEditFormPage';
import Verify from './customers/Verify';
import XCCourseInfo from './calendar/pages/XCCourseInfo';

import TasksPage from './customers/tasks/TasksPage';
import CustomerControlPanel from './customers/CustomerControlPanel';
import ShoppingCart from './customers/shopping/ShoppingCart';

// ADMIN
import PermissionsPage from './admin/permissions/pages/PermissionsPage';

// OCULUS
import ReportingPage from './admin/reporting/ReportingPage';

import Boarding from './simple/Boarding';
import Schooling from './simple/Schooling';
import Directions from './simple/Directions';
import Maps from './simple/Maps';
import ContactUs from './simple/ContactUs';
import './App.css';

// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

// Layouts

import DashboardLayout from './layouts/DashboardLayout';

import DivisionsPage from './scheduler/pages/DivisionsPage';
import EntriesPage from './scheduler/pages/EntriesPage';
import EventDivisionsPage from './scheduler/pages/EventDivisionsPage';

import UserPage from './users/pages/UserPage';
import UserHorsesPage from './users/pages/UserHorsesPage';
import UserPeoplePage from './users/pages/UserPeoplePage';
import UserEntriesPage from './users/pages/UserEntriesPage';
import ReleasePage from './users/pages/ReleasePage';

import StorePage from './store/pages/StorePage';
import ProductCategoriesPage from './store/pages/ProductCategoriesPage';
import ProductsPage from './store/pages/ProductsPage';
import ProductItemsPage from './store/pages/ProductItemsPage';

import ShoppingPage from './shopping/pages/ShoppingPage';

import { TestPage } from '../test/TestPage';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import createTheme from './theme';
import useTheme from '../hooks/useTheme';

import { fetchUser } from '../redux/actions';
import { fetchSaleHorses } from '../redux/actions/saleHorseActions';
// import * as rdd from 'react-device-detect';

ReactGA.initialize('G-C51PZJ3G1V');

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSaleHorses());
    dispatch(fetchUser());
  }, [dispatch]);

  const { theme } = useTheme();

  const admin = auth && auth.isAdmin();
  const loggedIn = auth !== null;

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <BrowserRouter>
        {/* <PageTracking userId={auth ? auth.id : null} /> */}
        {/* <DndProvider backend={HTML5Backend}> */}
        <Navigation />
        <br />
        <SentryRoutes>
          {/* <Routes> */}
          <Route path={'/scheduling'} element={<DashboardLayout />}>
            <Route
              path={'/scheduling/times'}
              element={admin ? <SchedulingPage /> : <SalesHorseList />}
            />
            <Route
              path={'/scheduling/home'}
              element={admin ? <SchedulingPage /> : <SalesHorseList />}
            />
            <Route
              path={'/scheduling/divisions'}
              element={admin ? <DivisionsPage /> : <SalesHorseList />}
            />
            <Route
              path={'/scheduling/entries'}
              element={admin ? <EntriesPage /> : <SalesHorseList />}
            />
            <Route
              path={'/scheduling/event/divisions'}
              element={admin ? <EventDivisionsPage /> : <SalesHorseList />}
            />
          </Route>
          <Route path={'/user'} element={<DashboardLayout />}>
            <Route
              path={'/user/home'}
              element={loggedIn ? <UserPage /> : <SalesHorseList />}
            />
            <Route
              path={'/user/dash'}
              element={loggedIn ? <CustomerControlPanel /> : <SalesHorseList />}
            />
            <Route
              path={'/user/cart'}
              element={loggedIn ? <ShoppingCart /> : <SalesHorseList />}
            />
            <Route
              path={'/user/horses'}
              element={loggedIn ? <UserHorsesPage /> : <SalesHorseList />}
            />
            <Route
              path={'/user/people'}
              element={loggedIn ? <UserPeoplePage /> : <SalesHorseList />}
            />
            <Route
              path={'/user/entries'}
              element={loggedIn ? <UserEntriesPage /> : <SalesHorseList />}
            />
          </Route>
          <Route path={'/store'} element={<DashboardLayout />}>
            <Route
              path={'/store/home'}
              element={admin ? <StorePage /> : <SalesHorseList />}
            />
            <Route
              path={'/store/productcategories'}
              element={admin ? <ProductCategoriesPage /> : <SalesHorseList />}
            />
            <Route
              path={'/store/products'}
              element={admin ? <ProductsPage /> : <SalesHorseList />}
            />
            <Route
              path={'/store/productitems'}
              element={admin ? <ProductItemsPage /> : <SalesHorseList />}
            />
          </Route>
          <Route path={'/admin'} element={<DashboardLayout />}>
            {/* <Route
                path={'/admin/home'}
                element={admin ? <StorePage /> : <SalesHorseList />}
              /> */}
            <Route
              path={'/admin/permissions'}
              element={admin ? <PermissionsPage /> : <SalesHorseList />}
            />
            {/* <Route
                path={'/store/products'}
                element={admin ? <ProductsPage /> : <SalesHorseList />}
              />
              <Route
                path={'/store/productitems'}
                element={admin ? <ProductItemsPage /> : <SalesHorseList />}
              /> */}
          </Route>

          <Route path={'/events'} element={<DashboardLayout />}>
            <Route path={'/events'} element={<EventsPage />} />
            <Route path="/events/manage/:id" element={<EventManagement />} />
            <Route path="/events/edit/:id" element={<EventForm />} />
            <Route path="/events/new" element={<EventForm />} />
            <Route path={'/events/scheduling'} element={<SchedulingPage />} />
          </Route>

          <Route path={'/tasks'} element={<DashboardLayout />}>
            <Route path={'/tasks'} element={<TasksPage auth={auth} />} />
          </Route>

          <Route path={'/reporting'} element={<DashboardLayout />}>
            <Route
              path={'/reporting'}
              element={<ReportingPage auth={auth} />}
            />
          </Route>

          <Route path={'/shop'} element={<DashboardLayout />}>
            {/* <Route path={'/shop'} element={<ProductItemsPage />} /> */}
            <Route path={'/shop'} element={<ShoppingPage />} />
          </Route>

          {/* <Route path={'/test'} element={<DashboardLayout />}> */}
          <Route path={'/test'} element={<TestPage />} />
          {/* </Route> */}

          <Route path={'/horses'} element={<DashboardLayout />}>
            <Route exact path="/horses" element={<HorsePage />} />
            <Route path="/horses/manage/:id" element={<HorseManagement />} />
            <Route path="/horses/edit/:id" element={<HorseForm />} />
            <Route exact path="/horses/feed" element={<HorseFeed />} />
            <Route path="/horses/feed/:id" element={<HorseManageFeed />} />
            <Route path={URLS.HORSES_NEW} element={<HorseForm />} />
          </Route>

          <Route path={'/barns'} element={<DashboardLayout />}>
            <Route exact path="/barns" element={<BarnsPage />} />
            <Route path="/barns/edit/:id" element={<BarnFormPage />} />
            <Route path="/barns/users/:id" element={<BarnUsers />} />
            <Route path="/barns/new" element={<BarnFormPage />} />
          </Route>

          <Route path={'/release'} element={<DashboardLayout />}>
            <Route exact path="/release" element={<ReleasePage />} />
          </Route>

          <Route path={'/sales'} element={<DashboardLayout />}>
            <Route
              exact
              path={'/sales'}
              element={<SalesHorseList auth={auth} />}
            />
            <Route
              path={'/sales/horse/:id'}
              element={<SaleHorseListingPage auth={auth} />}
            />
          </Route>

          <Route path={'/'} element={<DashboardLayout />}>
            <Route path="/" element={<Landing />} />
            <Route path="/sales" element={<SalesHorseList />} />
            {/* <Route path="/calendar" element={<CalendarPage />} /> */}
            <Route path={'/boarding'} element={<Boarding />} />
            <Route path={'/schooling'} element={<Schooling />} />
            <Route path={'/directions'} element={<Directions />} />
            <Route path={'/maps'} element={<Maps />} />
            <Route path={'/contactus'} element={<ContactUs />} />
          </Route>

          <Route exact path={URLS.ENTRIES} element={<EntryPage />} />
          <Route path={URLS.LOGIN} element={<DashboardLayout />}>
            <Route path={URLS.LOGIN} element={<NewLogin />} />
          </Route>
          <Route
            path={URLS.LOGIN + '/:sourcePage/:sourceId'}
            element={<NewLogin />}
          />
          <Route path={'/initiateresetpass'} element={<InitiateResetPass />} />
          <Route path={'/resetpass/:token'} element={<ResetPass />} />
          <Route path={'/resetsent'} element={<ResetSent />} />
          <Route path={'/invalidtoken'} element={<InvalidToken />} />
          <Route path={'/invalidlogin/:reason'} element={<LoginInvalid />} />

          <Route path={'/createaccount'} element={<DashboardLayout />}>
            <Route path={'/createaccount'} element={<CreateAccount />} />
          </Route>
          <Route path={'/accountcreated'} element={<DashboardLayout />}>
            <Route path={'/accountcreated'} element={<AccountCreated />} />
          </Route>

          <Route exact path={'/account'} element={<Dashboard />} />
          <Route path={'/verify'} element={<Verify />} />
          <Route exact path="/account/edit" element={<UserEditFormPage />} />
          <Route exact path="/account/community" element={<CommunityPage />} />
          <Route
            exact
            path="/account/community/add"
            element={<CommunityAddPage />}
          />
          <Route
            exact
            path="/account/community/invites"
            element={<CommunityInvitesPage />}
          />
          <Route exact path="/account/trainer" element={<TrainerManage />} />
          <Route
            exact
            path="/account/trainer/edit"
            element={<TrainerFormPage />}
          />
          <Route
            exact
            path="/account/trainer/preview/:id"
            element={<TrainerListingPage />}
          />
          <Route exact path="/trainers/:id" element={<TrainerListingPage />} />

          <Route exact path={'/tacksales'} element={<TackSaleTable />} />
          <Route
            path={'/tacksales/tack/:id'}
            element={<TackSaleListingPage />}
          />

          <Route exact path="/tack" element={<TackPage />} />
          <Route path="/tack/manage/:id/" element={<TackManage />} />
          <Route path="/tack/edit/:id" element={<TackFormPage />} />
          <Route path={'/tack/new'} element={<TackFormPage />} />

          <Route path={'/media/new'} element={<MediaFormPage />} />
          <Route path={'/media/edit/:id'} element={<MediaFormPage />} />
          <Route path={'/media'} element={<MediasManage />} />

          <Route exact path={URLS.ENTRIES} element={<EntryPage />} />

          <Route path={'/calendar'} element={<DashboardLayout />}>
            <Route exact path="/calendar" element={<CalendarPage />} />
            <Route path="/calendar/:id" element={<EventDetails />} />
            <Route
              path="/calendar/entryStatus/:id"
              element={<CalendarEntryStatus />}
            />
            <Route
              path="/calendar/rideTimes/:id"
              element={<CalendarRideTimesPage />}
            />
            <Route
              path="/calendar/xcCourseInfo/:id"
              element={<XCCourseInfo />}
            />
            <Route
              path="/calendar/sjCourseInfo/:id"
              element={<SJCourseInfo />}
            />
          </Route>

          <Route path={'/jumps'} element={<DashboardLayout />}>
            <Route exact path="/jumps" element={<JumpsPage />} />
            <Route path="/jumps/:id" element={<JumpManagement />} />
            <Route path="/jumps/edit/:id" element={<JumpForm />} />
            <Route path="/jumps/add/" element={<JumpForm />} />
            <Route path="/jumps/new" element={<JumpAddFormPage />} />
          </Route>

          <Route exact path="/users" element={<UserAdmin />} />
          <Route path="/users/edit/:id" element={<UserAdminEditFormPage />} />
          {/* </Routes> */}
        </SentryRoutes>
        {/* </DndProvider> */}
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
