import axios from 'axios';
import {
  EVENT_FETCHED,
  SET_EVENTS,
  SET_CALENDAR,
  CALENDAR_EVENT_FETCHED,
} from '../types';
import { eventToClass, eventsToClassArray } from '../../classes/Event';

export function eventFetched(event) {
  return {
    type: EVENT_FETCHED,
    event: eventToClass(event),
  };
}

export function calendarEventFetched(event) {
  return {
    type: CALENDAR_EVENT_FETCHED,
    event,
  };
}

export function setEvents(events) {
  return {
    type: SET_EVENTS,
    events: eventsToClassArray(events),
  };
}

export function setCalendar(events) {
  return {
    type: SET_CALENDAR,
    events,
  };
}

export const fetchEvent = (id) => async (dispatch) => {
  const res = await axios.get('/api/events/read/' + id);
  dispatch(eventFetched(res.data));
};

export const fetchEvents = () => async (dispatch) => {
  const res = await axios.get('/api/events/read');
  dispatch(setEvents(res.data));
};

export const createEvent = (values) => async (dispatch) => {
  const res = await axios.post('/api/events/create', values);
  dispatch(eventFetched(res.data));
};

export const updateEvent = (values) => async (dispatch) => {
  const res = await axios.post('/api/events/update', values);
  dispatch(eventFetched(res.data));
};

export const deleteEvent = (values) => async (dispatch) => {
  const res = await axios.post('/api/events/delete', values);
  dispatch(setEvents(res.data));
};

export const fetchCalendar = () => async (dispatch) => {
  const res = await axios.get('/api/events/calendar');
  dispatch(setCalendar(res.data));
};

export const fetchCalendarEvent = (id) => async (dispatch) => {
  const res = await axios.get('/api/events/calendar/read/' + id);
  dispatch(calendarEventFetched(res.data));
};

export const saveEvent = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/events/create', values);
  dispatch(eventFetched(res.data));
};

export const addDocToEvent = (values) => async (dispatch) => {
  const res = await axios.post('/api/events/docs/upload', values);
  dispatch(eventFetched(res.data));
};
